import { useState } from 'react'
import APIService from '../../utils/services/api.service'
import { Loading } from '../../utils/services/constants'
import toast from 'react-hot-toast'
import { getBaseUrl } from '../../utils/services/axios.service'
import DarkButton from './DarkButton'

const GetQuoteButton = () => {
    const [loadingQuote, setLoadingQuote] = useState(false);

    const initiateQuote = async () => {
        setLoadingQuote(true)
        const {data: responseData} = await APIService.initiateTrip();
        if (!responseData || !responseData.status || responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
        else {
          if (!responseData.data || !responseData.data.quote || !responseData.data.quote.reference) toast.error("unable to initiate quote at the moment, please try again later")
          else {
            const checkoutUrl = `${getBaseUrl()['checkout']}/${responseData.data.quote.reference}`;
            toast.success(responseData.message);
            const popupWindow = window.open(checkoutUrl, "_blank");
            if (popupWindow && (!popupWindow.closed || typeof popupWindow.closed === 'undefined')) popupWindow.focus();
          }
        }
        setLoadingQuote(false)
      }
  return (
    <DarkButton
          btn={{
            text: "Get Quick Quote",
            btnClassName: "px-4 bg-grey",
            outline: true,
            loadState: loadingQuote,
            clickFn: () => initiateQuote()
          }}
        />
  )
}

export default GetQuoteButton